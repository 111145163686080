import React from 'react'
import Markdown from "react-markdown"

import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {format} from 'date-fns';

import { getEntityUrl } from '../lib/helpers'

import ArticleSocial from './article-social';
import Breadcrumb from '../components/breadcrumb'
import Container from '../components/container'

import * as styles from './article-header.module.css'



export default function ArticleHeader(props) {
    const pageUrl = props.pageUrl
    const article = props.article
    const image = getImage(article?.main_image?.localFile)
    
    // author spacing
    var author_spacer = ""
    var author_spacer_last = ""
    if(article?.authors?.length === 2){
        author_spacer_last = " and "
    } else if (article?.authors?.length > 2) {
        author_spacer = ", "
        author_spacer_last = ", and "
    }

    // decide if you show the image or not
    var has_image = article?.main_image?1:0
    var has_override = article.page_type?.show_hero_image===null?0:1
    var force_show = has_override & (article.page_type?.show_hero_image?1:0)
    var force_hide = has_override & (article.page_type?.show_hero_image?0:1)
    var has_children = (article.children && article.children.length === 0)?0:1
    var show_main_image = has_image && ((has_children&&has_override)?force_show:1) && !force_hide && (has_override?force_show:1)
    
    return (
        <header>
            <div className={styles.pageHeader}>
                <Container>
                    <Breadcrumb thisPage={article}></Breadcrumb>
                    <h1 className={styles.title}>{article.title}</h1>
                    { article.subtitle && <div className={styles.subtitle}>
                        {article.subtitle}
                    </div>}
                </Container>
            </div>
            
            <div className={styles.pageSubHeader}>
                {article?.authors && article.authors.length>0 &&
                    
                    <div className={styles.authors}>
                        <div className={styles.avatars}>
                        {article.authors.map(entity => (
                            <>
                                {entity.main_image &&
                                    <span key={entity._id} className={styles.avatar}>
                                        <GatsbyImage 
                                            className={styles.avatarImg} 
                                            image={getImage(entity?.main_image?.localFile)} 
                                            alt={entity.main_image.alternativeText ? entity.main_image.alternativeText : entity.name } />
                                    </span>
                                }
                            </>
                        ))}
                        </div>
                        <div className={styles.mediaBody}>
                            By {article.authors.map((entity, index) => (
                                <>
                                    <Link key={entity._id} to={getEntityUrl(entity)}>{entity.name}</Link>
                                    { index<(article.authors.length-2) ? author_spacer:""}
                                    { index===(article.authors.length-2) ? author_spacer_last:""}
                                </>
                            ))}
                            <small>
                                <span  className={styles.dateBlock}>{ article.createdAt && !article.updatedAt &&
                                    <><time>{format( Date.parse(article.createdAt), "MMMM do, yyyy")}</time></>
                                }
                                { article.updatedAt && 
                                    <><time>Updated {format( Date.parse(article.updatedAt), "MMMM do, yyyy")}</time></>
                                }</span>
                            </small> 
                        </div>
                    </div>
                }
                { (!article.authors || (article.authors && article.authors.length===0)) &&
                    <small className={styles.dateBlock}>
                        { article.createdAt && !article.updatedAt && 
                            <><time>{format( Date.parse(article.createdAt), "MMMM do, yyyy")}</time></>
                        }
                        { article.updatedAt && 
                            <>Updated <time>{format( Date.parse(article.updatedAt), "MMMM do, yyyy")}</time></>
                        }
                    </small>
                }
                {article.authorities && article.authorities>0 && 
                    <ul>
                        {article.authorities.map(entity => (
                            <li key={entity._id}>
                                <Link to={getEntityUrl(entity)}>{entity.name}</Link>
                            </li>
                        ))}
                    </ul>
                }
                
                { article.executive_summary && article.executive_summary.data && article.executive_summary.data.executive_summary && 
                <div className={styles.executive_summary}>
                    <Markdown children={article.executive_summary.data.executive_summary} skipHtml={true} />
                </div>
                }
            </div>
            {
                show_main_image?(
                <figure className={styles.hero}>
                    <GatsbyImage image={image} alt={article.main_image.alternativeText ? article.main_image.alternativeText : article.title } />
                    <figcaption className={styles.heroImageCopyright}>{article.main_image.caption}</figcaption>
                </figure>):null
            }
            <ArticleSocial article={article} pageUrl={pageUrl}/>
        </header>
    )
  }