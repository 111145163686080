exports.getPageUrl = ({canonical_url, page_type, slug}) => {
    if (canonical_url){
      return canonical_url
    } else {
      // determine the url using slug and category
      if (page_type?.slug_prefix){
        return `/${page_type?.slug_prefix}/${slug}/`
      } else {
        return `/${slug}/`
      }
    }
  }
