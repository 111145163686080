// extracted by mini-css-extract-plugin
export var authors = "article-header-module--authors--0fc76";
export var avatar = "article-header-module--avatar--289e1";
export var avatars = "article-header-module--avatars--b6d64";
export var dateBlock = "article-header-module--dateBlock--39293";
export var executive_summary = "article-header-module--executive_summary--2cd25";
export var hero = "article-header-module--hero--36e06";
export var heroImageCopyright = "article-header-module--heroImageCopyright--5a31d";
export var mediaBody = "article-header-module--mediaBody--17fd0";
export var pageHeader = "article-header-module--pageHeader--d32ad";
export var pageSubHeader = "article-header-module--pageSubHeader--53593";
export var subtitle = "article-header-module--subtitle--138a4";
export var svg1 = "article-header-module--svg1--02cce";
export var svg2 = "article-header-module--svg2--85f68";
export var title = "article-header-module--title--bd0f9";