import React, {Component} from 'react'
import { getPageUrl } from '../lib/helpers';

import * as styles from './article-comments.module.css'


class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: {
        name: "",
        website: "",
        email: "",
        phone: "",
        message: "",
        slug: this.props.slug,
        reply_to: ""
      },
      submitting: false,
      success: false,
      error: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    //this.setState({value: event.target.value});
    const { newComment } = this.state
    const { name, value } = event.target

    this.setState({
      newComment: { ...newComment, [name]: value },
    })
  }

  //handleSubmit(event) {
  handleSubmit = async event => {


    event.preventDefault();

    // todo validate fields!
    // todo check captcha before sending

    // Set this so the button can't be pressed repeatedly
    this.setState({ submitting: true })

    const { newComment } = this.state
    const { slug, page, domain, project_id } = this.props

    try {
      // POST to /comments
      
      const response = await fetch("https://api.contentcurator.com/v1/messages/inbox", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
              "project_id":project_id,
              "to":"info@"+domain,
              "from":'"'+newComment.name+'" <'+newComment.email+'>',
              "type":"contactus",
              "page":page,
              "data":newComment}),
      })
  
      // Append comment and reset newComment
      this.setState(prevState => ({
        ...prevState,
        newComment: {
          name: "",
          website: "",
          email: "",
          phone: "",
          comment: "",
          slug: this.props.slug,
          reply_to: ""
        },
        success: true,
        error: false,
      }))
    } catch (error) {
      this.setState({ ...this.initialState, error: true })
    }
    // end onSubmit
  }


  render() {
    const { submitting, success, error, comments, newComment: { name, text } } = this.state

    const showError = () =>
      error && (
        <div className={styles.error}>
          <h1>Sorry</h1>
          <p>Comment failed to submit.</p>
          <p>Please refresh and try again.</p>
        </div>
      )

    const showSuccess = () =>
      success && (
        <div className={styles.success}>
          <h1>Thank You</h1>
          <p>Comment submitted!</p>
        </div>
      )
      
    const commentForm = () => (
      <>
        <div className={styles.title}>
          <span className={styles.commentFormTitle}>Leave a Comment</span>
        </div>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="name">Full Name</label>
                <input required type="text" className={styles.formcontrol} name="name" id="name" placeholder="Full name" aria-label="Full name"/>
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="url">Website (optional)</label>
                <input type="text" className={styles.formcontrol} name="url" id="url" placeholder="https://..." aria-label="https://..."/>
              </div>
            </div>
          </div>

          <div className={styles.col}>
            <label class="form-label" htmlFor="comment">Comments</label>
            <textarea required className={styles.formcontrol} name="comment" id="comment" placeholder="Tell us more details ..." aria-label="Tell us more details ..." rows="4"></textarea>
          </div>

          <div class="d-grid">
            <button type="submit" className={styles.contactButton}>Submit Comments</button>
          </div>

        </form>
      </>
    )


    return (
      

      <>
        {success || error ? showError() || showSuccess() : commentForm()}
      </>
    );
  }

  
}



export default function ArticleComments(props) {
    const domain = process.env.SITE_URL.replace("https://","").replace("www.","").replace(/\/+$/, '')

    return (
        <>
            <section className={styles.root} id="comments">

              <CommentForm slug={props.article.slug} project_id={process.env.GATSBY_SITE_ID} domain={domain} page={getPageUrl(props.article)}></CommentForm>
              
            </section>
        </>
    )
  }