import React from 'react'
import Markdown from "react-markdown"

import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import * as styles from './about-sidecallout.module.css'

export const query = graphql`
  query {
    strapiAboutSetting{
      id
      show_sidecallout_on_all_pages
      sidecallout_text {
        data {
          sidecallout_text
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
      sidecallout_image{
          url
          caption
          alternativeText
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
    }
    strapiDesign{
      color_accent_very_light
    }
  }
`

const AboutSideCallout = () => {
    const data = useStaticQuery(query);
    const about = data?.strapiAboutSetting;
    const image = getImage(about?.sidecallout_image?.localFile)
    var siteSettings_bgcolor = data?.strapiSiteSetting?.color_accent_very_light || "ffffff";

  return (
    <div className={styles.root}> 
    { about && 
      <>
        <div className={styles.callout} id="calloutdiv">
            { about && about.sidecallout_image &&
            <span className={styles.hero}>
                <GatsbyImage image={image} alt={about.sidecallout_image.alternativeText ? about.sidecallout_image.alternativeText : "" } />
            </span>
            }
            {about?.sidecallout_text && about?.sidecallout_text.data && about?.sidecallout_text.data.childMarkdownRemark &&
            <div className={styles.callout_text} 
              dangerouslySetInnerHTML={{__html: about?.sidecallout_text.data.childMarkdownRemark.html }} />
            }
        </div>
        <style jsx="true">{`
          #calloutdiv {
            background: #`+siteSettings_bgcolor+`;
          }
        `}</style>
      </>
    }
    </div>
  )
}

export default AboutSideCallout
